body {
  color: $black-color;
  font-size: $font-size-base;
  font-family: $sans-serif-family;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.main {
  position: relative;
  background: $white-color;
}


/* Animations */
.nav-pills .nav-link,
.navbar,
.nav-tabs .nav-link,
.sidebar .nav a,
.sidebar .nav a i,
.animation-transition-general,
.tag,
.tag [data-role="remove"],
.animation-transition-general {
  @include transition($general-transition-time, $transition-ease);
}

//transition for dropdown caret
.dropdown-toggle:after,
.bootstrap-switch-label:before,
.caret {
  @include transition($fast-transition-time, $transition-ease);
}

.dropdown-toggle[aria-expanded="true"]:after,
a[data-toggle="collapse"][aria-expanded="true"] .caret,
.card-collapse .card a[data-toggle="collapse"][aria-expanded="true"] i,
.card-collapse .card a[data-toggle="collapse"].expanded i {
  @include rotate-180();
}

.button-bar {
  display: block;
  position: relative;
  width: 22px;
  height: 1px;
  border-radius: 1px;
  background: $white-bg;

  & + .button-bar {
    margin-top: 7px;
  }

  &:nth-child(2) {
    width: 17px;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

.offline-doc {
  .navbar.navbar-transparent {
    padding-top: 25px;
    border-bottom: none;

    .navbar-minimize {
      display: none;
    }
    .navbar-brand,
    .collapse .navbar-nav .nav-link {
      color: $white-color !important;
    }
  }
  .footer {
    z-index: 3 !important;
  }
  .page-header {
    .container {
      z-index: 3;
    }
    &:after {
      background-color: rgba(0, 0, 0, 0.5);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }
}

.fixed-plugin {
  .dropdown-menu li {
    padding: 2px !important;
  }
}

// dynamicdialog-box

.ui-dialog-mask.ui-widget-overlay{
  z-index:999999 !important;
}
.ui-dialog .ui-dialog-titlebar{border-radius: 12px 12px 0 0}
.ui-dialog .ui-dialog-content{
  overflow-x: hidden; border-radius: 0 0 12px 12px;
  label{
    font-family: Montserrat,"Helvetica Neue",Arial,sans-serif;
    display: inline-block;
    margin-bottom: 0.3rem;
    font-size: .8571em;
    color:$dark-gray;
}
}
.ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:focus{box-shadow: none !important;}

// badge color

.badge {
  &.badge-default {
    @include badge-color($default-color);
  }
  &.badge-primary {
    @include badge-color($primary-color);
  }
  &.badge-info {
    @include badge-color($info-color);
  }
  &.badge-success {
    @include badge-color($success-color);
  }
  &.badge-warning {
    @include badge-color($warning-color);
  }
  &.badge-danger {
    @include badge-color($danger-color);
  }
  &.badge-neutral {
    @include badge-color($white-color);
    color: inherit;
  }
}

.card-user {
  form {
    .form-group {
      margin-bottom: 20px;
    }
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(204, 204, 195, 0.5);
  -webkit-border-radius: 10px;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 12px;
  background: rgba(204, 204, 204, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(204, 204, 204, 0.8);
}

::-webkit-scrollbar-thumb:hover {
  -webkit-box-shadow: inset 0 0 6px rgba(204, 204, 204, 0.8);
  width: 10px;
  height: 10px;
}

@mixin height-view($size) {
  height: #{$size}vh;
  overflow-y: auto;
}

@mixin height-view-size($size, $height) {
  height: calc(#{$size}vh + #{$height}px);
  overflow-y: auto;
}

.hv-10 {
  @include height-view(10);
}
.hv-20 {
  @include height-view(20);
}
.hv-30 {
  @include height-view(30);
}
.hv-40 {
  @include height-view(40);
}
.hv-50 {
  @include height-view(50);
}
.hv-60 {
  @include height-view(60);
}
.hv-70 {
  @include height-view(70);
}
.hv-80 {
  @include height-view(80);
}
.hv-90 {
  @include height-view(90);
}
.hv-40-30 {
  @include height-view-size(40, -30);
}
.hv-40-40 {
  @include height-view-size(40, -40);
}
.hv-40-50 {
  @include height-view-size(40, -50);
}
.hv-40-60 {
  @include height-view-size(40, -60);
}
.hv-40-70 {
  @include height-view-size(40, -70);
}
.hv-50-60 {
  @include height-view-size(50, -60);
}
.hv-55-60 {
  @include height-view-size(55, -60);
}
.hv-60-50 {
  @include height-view-size(60, -50);
}
.hv-60-60 {
  @include height-view-size(60, -60);
}
.hv-70-40 {
  @include height-view-size(70, -40);
}
.hv-70-50 {
  @include height-view-size(70, -50);
}
.hv-70-60 {
  @include height-view-size(70, -60);
}
.hv-70-70 {
  @include height-view-size(70, -70);
}
.hv-80-40 {
  @include height-view-size(80, -40);
}
.hv-80-50 {
  @include height-view-size(80, -50);
}
.hv-80-60 {
  @include height-view-size(80, -60);
}
.hv-90-40 {
  @include height-view-size(90, -40);
}
.hv-90-50 {
  @include height-view-size(90, -40);
}
.hv-90-55 {
  @include height-view-size(90, -40);
}
.hv-90-60 {
  @include height-view-size(90, -60);
}
.hv-90-70 {
  @include height-view-size(90, -70);
}
.hv-90-80 {
  @include height-view-size(90, -80);
}
.hv-90-90 {
  @include height-view-size(90, -90);
}


@mixin margin-top($px) {
  margin-top: #{$px}px;
}

.mt-0 {
  @include margin-top(0);
}
.mt-10 {
  @include margin-top(10);
}

@mixin padding-top($px) {
  padding-top: #{$px}px;
}

.pt-0 {
  @include padding-top(0);
}
.pt-10 {
  @include padding-top(10);
}

@mixin padding-bottom($px) {
  padding-bottom: #{$px}px;
}
.pb-0 {
  @include padding-bottom(0);
}
.pb-10 {
  @include padding-bottom(10);
}

@mixin padding-right($px) {
  padding-right: #{$px}px;
}
.pr-0 {
  @include padding-right(0);
}
.pr-10 {
  @include padding-right(10);
}

.disabled{
  cursor: not-allowed;
  opacity: 0.6;
}

.swal2-popup{width: 28em !important; padding: 0 !important; border-radius: 12px!important; }
.swal2-title{font-size: 1.375em !important;}
.swal2-styled.swal2-confirm{background-color: $primary-color !important;padding: .325em 1.5em;}
.swal2-styled.swal2-cancel{background-color: $default-bg !important;padding: .325em 1.5em;}
.swal2-icon .swal2-icon-content{font-size: 2.75em!important;}
.swal2-icon{width: 3em!important; height: 3em!important;}
.swal2-content{font-size: 1em!important;}
.swal2-header {background-color: #f3f3f3;margin: 0 0 20px 0;border-radius: 7px 7px 0 0; border: 1px solid #c8c8c8;}
.swal2-actions{margin: 1.25em 1em!important;}
.swal2-icon .swal2-success-circular-line-left{display: none;}
.swal2-icon .swal2-success-circular-line-right{display: none;}
.swal2-icon.swal2-success .swal2-success-fix{display: none;}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip{top: 1.7em !important;left: 0.5em!important;;width: 1em!important;;}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {top: 1.5em!important;right: 0.5em!important;width: 1.5em!important;}


