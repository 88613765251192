.card {
    border-radius: $border-radius-extreme;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    color: $card-black-color;
    margin-bottom: 20px;
    position: relative;
    border: 0 none;
    padding-bottom: 8px;

    -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  .ag-cell-value .btn{text-transform: inherit;}
    .card-body {
        padding: 15px 15px 10px 15px;
         
        .ag-header{font-family: Montserrat,"Helvetica Neue",Arial,sans-serif;}
        .card-body-list > div {
            padding: 12px 7px 12px 2px; 
            border-bottom: 1px solid $medium-gray;
            &:first-child {
                border-top: 1px solid $medium-gray;
            }
            &.text-primary {
                font-weight: $font-weight-semi;
            }
            &.link:hover {
                background-color: $bg-nude;
            }
            span {
                cursor: pointer;
                &:hover {
                    color: $new-blue;
                }
            }
        }

        &.table-full-width {
            padding-left: 0;
            padding-right: 0;
        }

        // .form-control.is-invalid{border-color:#e3e3e3 !important;}
    }

    .card-header {
        &:not([data-background-color]) {
            background-color: transparent;
        }
        padding:10px 15px 5px 15px;
        border: 0;
        text-align: left;

        .card-title {
            margin-top: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1.300em;
            position: relative;
            .card-button{
                button.btn-round{
                    margin-top: 0;
                    margin-bottom: 0;
                    opacity: 0.9;
                    font-size: 0.688em;
                    padding-top: 5px;
                    padding-bottom: 1px;
                    &:focus{
                        background-color: $primary-color !important;
                        color: $white-color;

                    }
                }
                .btn-round.dwn-btn {
                    font-weight: 500;
                    height: 25px;
                    padding-bottom: 0;
                    line-height: 20px;
                    padding-top: 0;
                }
            }
            abbr{
                width: 12px;
                height: 12px;
                display: inline-flex;
                position: relative;
            }
            abbr:before, abbr:after{ display: block;
                border-right: 1px solid #666666;
                content: '';
                width: 1px;
                height: 50%;
                position: absolute;
                left: 5px;
                right: 0;
                top: 0;
                transform: skewX(45deg);
            }
            abbr:after{
                bottom: 0;
                top: auto;
                transform: skewX(-45deg);
            }
        }
    }

    .map {
        border-radius: $border-radius-small;

        &.map-big {
            height: 400px;
        }
    }

    &[data-background-color="orange"] {
        background-color: $primary-color;

        .card-header {
            background-color: $primary-color;
        }

        .card-footer {
            .stats {
                color: $white-color;
            }
        }
    }

    &[data-background-color="red"] {
        background-color: $danger-color;
    }

    &[data-background-color="yellow"] {
        background-color: $warning-color;
    }

    &[data-background-color="blue"] {
        background-color: $info-color;
    }

    &[data-background-color="green"] {
        background-color: $success-color;
    }

    .image {
        overflow: hidden;
        height: 200px;
        position: relative;
    }

    .avatar {
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 15px;
    }

    .numbers {
        font-size: 2em;
    }

    .big-title {
        font-size: 12px;
        text-align: center;
        font-weight: 500;
        padding-bottom: 15px;
    }

    label {
        font-size: $font-size-small;
        margin-bottom: 5px;
        color: $dark-gray;
    }

    .card-footer {
        background-color: transparent;
        border: 0;

        .stats {
            i {
                margin-right: 5px;
                position: relative;
                top: 0px;
                color: $default-color;
            }
        }

        .btn {
            margin: 0;
        }
    }

    &.card-plain {
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;

        .card-body {
            padding-left: 5px;
            padding-right: 5px;
        }

        img {
            border-radius: $border-radius-extreme;
        }
    }
    &.card-login{
        .card-header{padding:15px 0 0 0; margin:0 auto;
        h3{font-size: 1.25em; margin: 10px 0 0 0;}
        }
        .card-body{
           .input-group-text{background-color:transparent; border:none;position: absolute; z-index: 999;top: 5px;}
           .input-group .form-control{ padding: 10px 10px 10px 35px;border: 1px solid #DDDDDD !important;}
           .form-control:invalid, .form-control.is-invalid{border-color: #dc3545 !important;}
           .input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child){border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;}
           
        }
    }
}

