@font-face {
    font-family: 'icomoon';
    src: url('/assets/fonts/icomoon.eot?wq5pvc');
    src: url('/assets/fonts/icomoon.eot?wq5pvc#iefix') format('embedded-opentype'), url('/assets/fonts/icomoon.ttf?wq5pvc') format('truetype'), url('/assets/fonts/icomoon.woff?wq5pvc') format('woff'), url('/assets/fonts/icomoon.svg?wq5pvc#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-user:before {
    content: "\e900";
}

.icon-building:before {
    content: "\e901";
}

.icon-database:before {
    content: "\e902";
}

.icon-audit-1:before {
    content: "\e905";
}

.icon-role-3:before {
    content: "\e904";
}

.icon-database-configuration:before {
    content: "\e903";
}

.icon-padlock:before {
    content: "\e924";
}

.icon-logout:before {
    content: "\e925";
}

.icon-profile:before {
    content: "\e916";
}

.sidebar .sidebar-wrapper {
    overflow-x: hidden;
    height: 100vh;
    .active-pro {
        bottom: 22px;
    }
}


/*new icons*/

@font-face {
    font-family: 'icomoon';
    src: url('/assets/fonts/newicon/icomoon.eot?cbycnc');
    src: url('/assets/fonts/newicon/icomoon.eot?cbycnc#iefix') format('embedded-opentype'), url('/assets/fonts/newicon/icomoon.ttf?cbycnc') format('truetype'), url('/assets/fonts/newicon/icomoon.woff?cbycnc') format('woff'), url('/assets/fonts/newicon/icomoon.svg?cbycnc#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-Audit-View:before {
    content: "\e900";
}

.icon-DataSource:before {
    content: "\e901";
}

.icon-DataSource-Config:before {
    content: "\e902";
}

.icon-List:before {
    content: "\e903";
}

.icon-Organisation:before {
    content: "\e904";
}

.icon-Role:before {
    content: "\e905";
}

.icon-User:before {
    content: "\e906";
}

@font-face {
    font-family: 'icomoon';
    src: url('/assets/fonts/okta/icomoon.eot?k4a3f7');
    src: url('/assets/fonts/okta/icomoon.eot?k4a3f7#iefix') format('embedded-opentype'), url('/assets/fonts/okta/icomoon.ttf?k4a3f7') format('truetype'), url('/assets/fonts/okta/icomoon.woff?k4a3f7') format('woff'), url('/assets/fonts/okta/icomoon.svg?k4a3f7#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon-okta:before {
    content: "\e9900";
    color: #fbc658;
}

.icon-okta-login:before {
    content: "\e9900";
}

input[type="checkbox"]:checked+label .icon-okta:before {
    content: "\e9901";
    color: #51cbce;
}

.icon-okta-primary:before {
    content: "\e9901";
    color: #17A3B5;
}