.display-none {
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    opacity: 0;
    height: 0;
    padding: 0px !important;
}

.display-block {
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    opacity: 1;
}

.wrapper-full-page {
    background-color: rgb(15, 115, 115);
}

.full-screen {
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    height: 80vh;
    flex: 0 0 100%;
    max-width: 100%;
}

.cell-updated {
    // color: $new-orange !important;
    background-color: rgba($bg-warning, 0.2);
}

.cell-updated-danger {
    // color: $new-red !important;
    background-color: rgba($bg-danger, 0.2);
}
.row-inserted {
    background-color: $warning-color-opacity;
}

.legend-orange {
    color: rgba($bg-warning, 0.5);
}

.row-orange {
    background:  rgba($mid-orange, 0.4);
}

.ag-pinned-left-cols-container .row-orange {
    background: none !important;
}

.legend-yellow {
    color: $warning-color-opacity;
}

.legend-mid-blue {
    color: rgba($mid-blue, 0.4);
}

.legend-dark-blue {
    color: rgba(182, 182, 182, 0.6);
}

.row-red {
    background: rgba($bg-danger, 0.4) !important;
}

.ag-pinned-left-cols-container .row-red {
    background: none !important;
}

.legend-red {
    color: rgba($bg-danger, 0.5);
}

.ag-custom-header .primary-key {
    color: $primary-color;
    background: none;
}

.primary-key {
    opacity: 0.9;
    background: $light-blue;
}

.primary-key-non-auto {
    opacity: 0.85;
    background: rgba(182, 182, 182, 0.6);
}

.text-link {
    cursor: pointer;
    &:hover {
        color: $primary-color;
    }
}

.ag-header-icon.ag-header-cell-menu-button {
    opacity: 1 !important;
}

.ag-checkbox-disabled {
    pointer-events: none;
    label.mat-checkbox-layout {
        box-shadow: none;
        border-color: #666666;
        background-color: #666666;
    }
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        box-shadow: none;
        border-color: #666666;
        background-color: #666666;
    }
}
.cell-updated-none {
    background-color: #fff;
}
.cell-updated-danger1 {
    background-color: $bg-danger;
}

#myGrid .ag-cell-wrapper {
    display: grid !important;
}
